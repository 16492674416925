:root {
    --brand-primary            : #C3002F;
    --brand-primary-lighten-10 : #f6003b;
    --brand-primary-lighten-40 : #ff90ab;
    --brand-primary-darken-5   : #aa0029;
    --brand-primary-darken-10  : #900023;
    --brand-primary-darken-12  : #860020;
    --brand-primary-opacity-25 : #c3002f40;
    --brand-primary-opacity-50 : #c3002f80;

    --brand-secondary          : var(--brand-primary);

    --highlight                : #ff97b0;
    --highlight-tint           : #ffe6ec;

    --navbar-height            : 60px;
    --navbar-brand-width       : 70px;
    --navbar-brand-height      : 58px;
    --navbar-brand-margin-left : 10px;
    --navbar-brand-logo-size   : contain;
    --navbar-brand-logo        : url('/assets/images/nissan-us_logo.png');
    --navbar-brand-logo-mobile : url('/assets/images/nissan-us_logo.png');

    --logo-gap                 : 24px;
    --logo-bg-size             : contain;
    --login-logo-height        : auto;
    --login-logo-width         : 200px;
    --login-logo               : url('/assets/images/PoweredByShift.png');
    --login-brand-logo         : url('/assets/images/clients/nissan-us/login_logo.png');
    --login-brand-logo-width   : 115px;
    --login-brand-logo-height  : 120px;

    --sidebar-width            : 220px;
    --mobile-sidebar-width     : 220px;

    --sidebar-bg               : #000;
    --sidebar-bg-darken-3      : black;
    --sidebar-bg-darken-5      : black;
    --sidebar-bg-darken-10     : black;
    --sidebar-bg-lighten-5     : #0d0d0d;
    
    --combined-bar-green       : #18b52d;
    --combined-bar-blue        : #224197;
    --combined-bar-yellow      : #f9bb11;
    --combined-bar-red         : #c51230;
}